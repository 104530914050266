

export const testimonialsData = [
  {
    id: 1,
    imageUrl: "t-image1.jpg",
    review:
      "I made the right choice by choosing the StrawHat and by choosing the right plan and program I already achieved my ideal body!",
    name: 'MONKEY D. LUFFY',
    status : 'ENTREPRENEUR'
  },
  {
    id :2,
    imageUrl: "t-image2.jpg",
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'SANJI VINSMOKE',
    status: 'COACH'
  },
  {
    id: 3,
    imageUrl: "t-image3.jpg",
    review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'RONOA ZORO',
    status: "CUSTOMER"
  }
];